import React from "react";
import { openLightbox } from "../stores/useLightboxStore";

type Props = Image;

export default function Thumbnail(props: Props) {
  const { alt, small, fullsize, caption } = props;

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    openLightbox(props);
  };

  return (
    <div className="image-inner">
      <a href={fullsize} onClick={handleClick} className="thumbnail-container">
        <img src={small} alt={alt} />
      </a>
      {caption && <figcaption dangerouslySetInnerHTML={{ __html: caption }} />}
    </div>
  );
}
