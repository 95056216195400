import React, { useEffect } from "react";
import useListsStore, { fetchLists, inAnyList } from "../stores/useListsStore";
import { openModal } from "../stores/useModalStore";
import Modal from "./Modal";
import ListsUI from "./ListsButton/ListsUI";

interface Props {
  artist: Artist;
  strings: Localizations;
  fetch?: boolean;
}

export default function ListsButton(props: Props) {
  const { artist, fetch, strings } = props;

  useListsStore();
  const loaded = useListsStore((state) => state.loaded);

  useEffect(() => {
    if (fetch) {
      void fetchLists();
    }
  }, [fetch]);

  if (!loaded) {
    return "";
  }

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    openModal(
      <Modal>
        <ListsUI artist={artist} strings={strings} />
      </Modal>
    );
  };

  const buttonClass = inAnyList(artist) ? "added" : "";

  return (
    <div className="lists-button">
      <button className={buttonClass} onClick={handleClick}>
        {strings.add_artist_to_list}
      </button>
    </div>
  );
}
